import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { appService } from "../../services/appServices";

export const fetchRoleByPublicationId = createAsyncThunk(
  "role/publication/id",
  async (id: any) => {
    const response = await appService.getRoleByPublicationId(id);
    return response;
  }
);
export const fetchRoleById = createAsyncThunk(
  "role/id",
  async (id: any) => {
    const response = await appService.getRoleById(id);
    return response;
  }
);
export const deleteRoleById = createAsyncThunk(
  "role/delete",
  async (id: number) => {
    const response = await appService.deleteRole(id);
    return response;
  }
);

export const updateRoleById = createAsyncThunk(
  "role/update",
  async ( idData: any) => {
    const { id, data } = idData;
    const response = await appService.patchRoleById(id,data);
    if (response.success()) {
      // window.location.reload();
      return response;
    } 
    
  }
);
interface IInitialState {
  
  allRoles: any[];
  rolesByPublicationId: any;
  rolesById: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}


const initialState: IInitialState = {
  allRoles: [],
  rolesByPublicationId: {},
  rolesById: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

const roleSlice: any = createSlice({
  name: "roles",
  initialState,
  reducers: {
    deleteRolesById(state: any, action: any) {
      state.allRoles = action.payload;
    },
    addNewRoles(state: any, action: any) {
      state.allRoles.roles.push(action.payload);
    },
    permissionsArray(state: any, action: any) {
      
      state.rolesById = action.payload;
    },
    setRoleById(state: any, action: any) {
      state.rolesById = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateRoleById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateRoleById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateRoleById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchRoleByPublicationId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRoleByPublicationId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allRoles = action.payload.response;
      })
      .addCase(fetchRoleByPublicationId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchRoleById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRoleById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        action.payload.response.permissions = JSON.parse(
          action.payload.response.permissions
        );
        state.rolesById = action.payload.response;
      })
      .addCase(fetchRoleById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { setRoleById,addNewRoles, deleteRolesById, permissionsArray } = roleSlice.actions;
export default roleSlice.reducer;
