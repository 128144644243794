import { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "./components";
import { readCapabilities } from "./components/index";
import Callback from "./pages/auth/callback";
import Notifications from "./pages/notifications";
import { fetchPublicationByDomain } from "./store/publication/publicationSlice";
import { fetchRoleByPublicationId } from "./store/roles/roleSlice";
import { getRoleId, getUserId, updateUserData } from "./store/user/userSlice";
import { appService } from "./services/appServices";
import { unwrapResult } from "@reduxjs/toolkit";


const Roles = lazy(() => import("./pages/settings/roles"));

const CreateAccount = lazy(() => import("./pages/createAccount"));
const CreateStory = lazy(() => import("./pages/admin/createStory"));
const CreatePublication = lazy(() => import("./pages/createPublication"));
const Publication = lazy(() => import("./pages/publication"));
const SeoSetting = lazy(() => import("./pages/seoSetting"));
const PreView = lazy(() => import("./pages/preView"));
const Account = lazy(() => import("./pages/settings/account"));
const EditProfile = lazy(() => import("./pages/settings/editProfile"));
const Users = lazy(() => import("./pages/settings/users"));
const AddNewRole = lazy(() => import("./pages/settings/addNewRole"));
const ManageRoles = lazy(() => import("./pages/settings/manageRoles"));
const Publications = lazy(() => import("./pages/settings/publications"));
const YourStory = lazy(() => import("./pages/yourStory"));
const CategoryList = lazy(() => import("./pages/categoryList"));
const AllCategory = lazy(() => import("./pages/allCategory"));
const NotFound = lazy(() => import("./pages/notFound"));
const Author = lazy(() => import("./pages/author"));



function App() {
  const dispatch = useDispatch<any>();
  const state = useSelector<any>((state) => state);
  const navigate = useNavigate();
  const { permissions, user, userIdNumber, domain, token, refreshToken }: any =
    useSelector<any>((state) => state.auth);
  const { publication }: any = useSelector<any>((state) => state.publication);
  const { id } = publication;
  const { permissionsName }: any = useSelector<any>((state) => state.auth);
  // let idUser = storageManager.get(keys.USER_ID);
  // const domain:string = storageManager.get(keys.DOMAIN)



  
  

  
  axios.interceptors.request.use(
    async (config: any) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers["x-host"] = domain;
      return config;
    },
    (error: any) => Promise.reject(error)
  );
 

   axios.interceptors.response.use(
     (res: any) => {
       return Promise.resolve(res);
     },
     async (error: any) => {
       const originalConfig = error.config;
       if (error.response?.status === 401 && !originalConfig._retry) {
         try {
          originalConfig._retry=true;
           const response: any = await appService.refresh({
             token: refreshToken,
             domain: domain,
           });
           const accessToken = response.response.accessToken;
           const newRefreshToken = response.response.refreshToken;
           const userId = response.response.userId;
           const domainByRefresh = response.response.domain;
           const data: any = {
             refreshToken: newRefreshToken,
             accessToken,
             userId,
             domain: domainByRefresh,
           };
           const resultAction = dispatch(updateUserData(data));
           const originalPromiseResult = unwrapResult(resultAction);
           if (originalPromiseResult.accessToken) {
             // window.location.replace(`${window.location.href}`)
             window.location.reload();
           }

           //  fetcher();

           return axios(originalConfig);

           // window.location.replace(`http://${domain}.asdevops.in`);
         } catch (_error) {
           return Promise.reject(_error);
         }
       }

       return Promise.reject(error);
     }
   );
   useEffect(() => {
     if (domain !== "" && userIdNumber !== "") {
       dispatch(getUserId(userIdNumber));
       dispatch(fetchPublicationByDomain(domain));
     }
   }, [userIdNumber, domain, token]);
   useEffect(() => {
     // const fetch = ()=>{

     if (publication?.id) {
       dispatch(fetchRoleByPublicationId(publication?.id));
       const roleBody = {
         publicationId: publication?.id,

         userId: userIdNumber,
       };
       dispatch(getRoleId(roleBody));
     }
     // }
     //  if (window.location.pathname !== "/auth/callback") {
     //    fetch();
     //  }
   }, [id]);
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/auth/callback" element={<Callback />} />
          {/* {permissions.length > 0 &&
          (readCapabilities("publication", permissions)?.allow?.includes(
            "manage"
          ) ||
            readCapabilities("publication", permissions)?.allow?.includes(
              "create"
            )) ? ( */}
          <Route path="/" element={<Publication />} />
          {/* ) : (
            <Route path="/" element={<NotFound />} />
          )} */}
          {permissions.length > 0 &&
            (readCapabilities("story", permissions)?.allow?.includes("manage") ||
              readCapabilities("story", permissions)?.allow?.includes(
                "create"
              )) ? (
            <Route path="/createStory" element={<CreateStory />} />
          ) : (
            <Route path="/createStory" element={<NotFound />} />
          )}

          <Route path="/seosetting/:id" element={<SeoSetting />} />
          <Route path="/preview/:id" element={<PreView />} />
          <Route path="/settings/account" element={<Account />} />
          <Route path="/settings/editprofile/:id" element={<EditProfile />} />
          {permissions.length > 0 &&
            (readCapabilities("publication", permissions)?.allow?.includes(
              "manage"
            ) ||
              readCapabilities("publication", permissions)?.allow?.includes(
                "read"
              )) ? (
            <Route path="/settings/publications" element={<Publications />} />
          ) : (
            <Route path="/settings/publications" element={<NotFound />} />
          )}
          {permissions.length > 0 &&
            (readCapabilities("story", permissions)?.allow?.includes("manage") ||
              readCapabilities("story", permissions)?.allow?.includes("read")) ? (
            <Route path="/story" element={<YourStory />} />
          ) : (
            <Route path="/story" element={<NotFound />} />
          )}
          {permissions.length > 0 &&
            (readCapabilities("category", permissions)?.allow?.includes(
              "manage"
            ) ||
              readCapabilities("category", permissions)?.allow?.includes(
                "read"
              )) ? (
            <Route path="/categories" element={<CategoryList />} />
          ) : (
            <Route path="/categories" element={<NotFound />} />
          )}

          <Route path="/author" element={<Author />} />
          <Route path="/category/:id" element={<AllCategory />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/settings/manageroles/:id" element={<ManageRoles />} />
          <Route path="/settings/addroles" element={<AddNewRole />} />
          <Route path="/settings/users" element={<Users />} />
          {/* {permissionsName == "Super Admin" ? ( */}
          <Route path="/settings/roles" element={<Roles />} />
          {/* ) : (
            <Route path="/settings/roles" element={<NotFound />} />
          )} */}
          <Route path="/404" element={<NotFound />} />

        </Routes>
      </Suspense>
    </>
  );
}

export default App;
