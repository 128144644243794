import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { appService } from "../../services/appServices";
import { StroiesTypes } from "../../services/cacheKeys";

export const makeCategory = createAsyncThunk(
  "category/create",
  async (data: any) => {
    //  const { name, slug, publication } = data;
    const response = await appService.postCategory(data);
    return response;
  }
);

export const fetchCategory = createAsyncThunk("category/domainAndPagination", async (data: any) => {
  const { domain, size, page } = data;
  const response = await appService.getCategorybyDomain(domain, size, page);
  return response;
});
export const fetchCategoryByStatus = createAsyncThunk(
  "category/status",
  async (data: any) => {
    const { domain, size, page , status } = data;
    const response = await appService.getCategorybyStatus(domain, size, page , status);
    return response;
  }
);
export const fetchCategoryByDomain = createAsyncThunk(
  "category/domain",
  async (domain: any) => {
    
    const response = await appService.getCategorybyOnlyDomain(domain);
    return response;
  }
);

 interface IInitialState {
  category: {
    categories: any[];
    pagination: any;
  };
  categoryByDomain: {
    categories: any[];
    pagination: any;
  };
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}

const initialState: IInitialState = {
  category: {
    categories: [],
    pagination: {
      Total: { total: 0, draft: 0, published: 0 },
      draft: 0,
      published: 0,
      total: 0,
      pageSize: 0,
      pages: 0,
      searchTerm: "",
      start: 0,
      status: StroiesTypes,
    },
  },
  categoryByDomain: {
    categories: [],
    pagination: {
      Total: { total: 0, draft: 0, published: 0 },
      draft: 0,
      published: 0,
      total: 0,
      pageSize: 0,
      pages: 0,
      searchTerm: "",
      start: 0,
      status: StroiesTypes,
    },
  },
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

const categorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    createCategorySlice(state: any, action: any) {
      state?.category?.categories?.shift(action.payload);
    },
    deleteCategoryById(state: any, action: any): any {
      state.category = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makeCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(makeCategory.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.category.push(action.payload.response);
        // state.allCategory.push(action.payload);
      })
      .addCase(fetchCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.category = action.payload;
      })
      .addCase(fetchCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchCategoryByStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCategoryByStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        state.category = action.payload;
      })
      .addCase(fetchCategoryByStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchCategoryByDomain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCategoryByDomain.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        state.categoryByDomain = action.payload;
      })
      .addCase(fetchCategoryByDomain.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const { createCategorySlice, deleteCategoryById } = categorySlice.actions;
export const selectCategory = (state: any) => state.category.category;
export const allSelectCategory = (state: any) =>
  state.category.allCategory;
export default categorySlice.reducer;
