
import Header from './Header';

export interface IPrimaryLayout extends React.ComponentPropsWithoutRef<'div'> {
  isAuthor?: boolean;
  handleClick?:any;
  showMenu?: boolean;
  setShowMenu?:boolean;
  showNotification?:boolean;
  setShowNotification?:boolean;
}

const PrimaryLayout: React.FC<IPrimaryLayout> = ({ children, isAuthor ,handleClick , setShowMenu , showMenu , showNotification , setShowNotification }) => {
  return (
    <>
      <div>
        <Header
          showNotification={showNotification}
          setShowNotification={setShowNotification}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          handleClick={handleClick}
          isAuthor={isAuthor}
        />
        {children}
      </div>
    </>
  );
};

export default PrimaryLayout;
