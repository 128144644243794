import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import { appService } from "../../services/appServices";
import { AppDispatch } from "../../store/store";
import { updateUserData } from "../../store/user/userSlice";
export default function Callback() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search)
  const accesstoken = params.get("accesstoken");
  const domain: any = params.get('domain');


  const body: any = { domain, accesstoken }
  const path: any = params.get("path");



  useEffect(() => {
    const fetcher = async () => {
      const response = await appService.login(body);
      
      const accessToken = response.response.accessToken;
      const refreshToken = response.response.refreshToken;
      const userId = response.response.userId;
      const domain = response.response.domain;
      const data: any = { refreshToken, accessToken, userId, domain };
      const resultAction = dispatch(updateUserData(data))
      const originalPromiseResult = unwrapResult(resultAction)


      if (originalPromiseResult.accessToken
      ) {
        navigate("/")
      }
    };
    fetcher()
  }, []);

 




  return <div><Loader/></div>
}