import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import categoryReducer from "./Category/categorySlice";
import publicationReducer from "./publication/publicationSlice";
import rolesReducer from "./roles/roleSlice";
import storyReducer from "./story/storySlice";
import authReducer from './user/userSlice';

const persistConfig = {
  key: 'root',

  storage,
};
const persistConfigPublication = {
  key: "root-publication",

  storage,
};
const persistConfigRoles = {
  key: "root-roles",

  storage,
};
const persistedReducer = persistReducer(persistConfig, authReducer );
const persistedReducerPublication = persistReducer(
  persistConfigPublication,
  publicationReducer
);
const persistedReducerRoles = persistReducer(persistConfigRoles, rolesReducer);

export const store = configureStore({
  reducer: {
    auth: persistedReducer,
    stories: storyReducer,
    publication: persistedReducerPublication,
    category: categoryReducer,
    roles: persistedReducerRoles,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
