import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { appService } from "../../services/appServices";
import { StroiesTypes } from "../../services/cacheKeys";

export const fetchStoryBySlug = createAsyncThunk(
  "story/slug",
  async (id: number) => {
    const response = await appService.getStoriesBySlug(id);

    return response;
  }
);

export const fetchCategories = createAsyncThunk("story/category", async () => {
  const response = await appService.getCategoryPrivate();
  
  return response.categories;
});
export const createStory = createAsyncThunk(
  "story/create",
  async (data: any) => {
    const response = await appService.createStory(data);
    return response;
  }
);
interface IInitialState {
  stories: {
    stories: any[];
    pagination: any;
  };
  category: any[];
  story: any;
  createdStory: any;
  isError:boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}
const initialState: IInitialState = {
  stories: {
    stories: [],
    pagination: {
      Total: { total: null, draft: null, published: null },
      draft: 0,
      published: 0,
      total: 0,
      pageSize: 0,
      pages: 0,
      searchTerm: "",
      start: 0,
      status: StroiesTypes,
    },
  },
  category: [],
  story: {},
  createdStory: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

const storySlice = createSlice({
  name: "stories",
  initialState,
  reducers: {
    add(state: any, action: any) {
      state.stories = action.payload;
    },
    deleteStoryById(state: any, action: any):any {
      state.stories = action.payload;
    },
    searchStoryByName(state: any, action: any) {
      state.stories = action.payload;
    },

    storyCreation: (state: any) => ({
      ...state,

      story: {},
    }),
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchStoryBySlug.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStoryBySlug.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        state.story = action.payload;
      })
      .addCase(fetchStoryBySlug.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.category = action.payload;
      })
      .addCase(createStory.pending, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
      })
      .addCase(createStory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createdStory = action.payload;
      });
  },
});
export const { add, deleteStoryById, storyCreation, searchStoryByName } =
  storySlice.actions;
export const selectPost = (state: any) => state.stories.story;
export const selectCreateStory = (state: any) => state.stories.createdStory;
export const selectCategory = (state: any) => state.stories.category;
export default storySlice.reducer;
