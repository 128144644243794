import { lazy, useState } from "react";
const PrimaryLayout = lazy(() => import("../components/PrimaryLayout"));
const FollowList = lazy(() => import("../components/FollowList"));

const Notifications = () => {
  const [showMenu, setShowMenu]: any = useState(false);
  const [showNotification, setShowNotification]: any = useState(false);

  return (
    <div
      onClick={() => {
        setShowMenu(false);
        setShowNotification(false);
      }}
    >
      <PrimaryLayout
        showNotification={showNotification}
        setShowNotification={setShowNotification}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        isAuthor={true}
      />
      <div className=" container_width flex gap-x-10 ">
        <aside className="basis-[75%] pt-10">
          <p className="text-[#251D19] text-[22px] font-semibold">
            Notifications
          </p>
          <div className="space-y-10 pt-8">
            <div>
              <div className="flex items-center gap-x-4">
                <img
                  className="w-[45px] h-[45px] rounded-full"
                  src="/images/Ellipse30@2x.png"
                  alt=""
                />
                <p className="text-base text-[#251D19] font-regular leading-[25px] ">
                  Lorem Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry. Lorem
                  Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry.
                </p>
              </div>
              <div className="flex gap-x-6 items-center justify-end">
                <p className="text-[#757575] text-sm font-medium">Today</p>
                <p className="text-[#757575] text-sm font-medium">10:20 AM</p>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-x-4">
                <img
                  className="w-[45px] h-[45px] rounded-full"
                  src="/images/Ellipse30@2x.png"
                  alt=""
                />
                <p className="text-base text-[#251D19] ">
                  Lorem Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry. Lorem
                  Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry.
                </p>
              </div>
              <div className="flex gap-x-6 items-center justify-end">
                <p className="text-[#757575] text-sm font-medium">Today</p>
                <p className="text-[#757575] text-sm font-medium">10:20 AM</p>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-x-4">
                <img
                  className="w-[45px] h-[45px] rounded-full"
                  src="/images/Ellipse30@2x.png"
                  alt=""
                />
                <p className="text-base text-[#251D19]">
                  Lorem Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry. Lorem
                  Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry.
                </p>
              </div>
              <div className="flex gap-x-6 items-center justify-end">
                <p className="text-[#757575] text-sm font-medium">Today</p>
                <p className="text-[#757575] text-sm font-medium">10:20 AM</p>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-x-4">
                <img
                  className="w-[45px] h-[45px] rounded-full"
                  src="/images/Ellipse30@2x.png"
                  alt=""
                />
                <p className="text-base text-[#251D19]">
                  Lorem Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry. Lorem
                  Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry.
                </p>
              </div>
              <div className="flex gap-x-6 items-center justify-end">
                <p className="text-[#757575] text-sm font-medium">Today</p>
                <p className="text-[#757575] text-sm font-medium">10:20 AM</p>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-x-4">
                <img
                  className="w-[45px] h-[45px] rounded-full"
                  src="/images/Ellipse30@2x.png"
                  alt=""
                />
                <p className="text-base text-[#251D19]">
                  Lorem Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry. Lorem
                  Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry.
                </p>
              </div>
              <div className="flex gap-x-6 items-center justify-end">
                <p className="text-[#757575] text-sm font-medium">Today</p>
                <p className="text-[#757575] text-sm font-medium">10:20 AM</p>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-x-4">
                <img
                  className="w-[45px] h-[45px] rounded-full"
                  src="/images/Ellipse30@2x.png"
                  alt=""
                />
                <p className="text-base text-[#251D19]">
                  Lorem Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry. Lorem
                  Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry.
                </p>
              </div>
              <div className="flex gap-x-6 items-center justify-end">
                <p className="text-[#757575] text-sm font-medium">Today</p>
                <p className="text-[#757575] text-sm font-medium">10:20 AM</p>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-x-4">
                <img
                  className="w-[45px] h-[45px] rounded-full"
                  src="/images/Ellipse30@2x.png"
                  alt=""
                />
                <p className="text-base text-[#251D19]">
                  Lorem Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry. Lorem
                  Ipsum is simply dummy text of the printing typesetting
                  industry. text of the printing and typesetting industry.
                </p>
              </div>
              <div className="flex gap-x-6 items-center justify-end">
                <p className="text-[#757575] text-sm font-medium">Today</p>
                <p className="text-[#757575] text-sm font-medium">10:20 AM</p>
              </div>
            </div>
          </div>
        </aside>
        <aside className="basis-[25%] pt-4  hidden md:block">
          <div className="sticky top-5">
          <FollowList />
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Notifications;
