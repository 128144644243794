import axios, { AxiosRequestConfig } from "axios";
import APIResponse from "../interfaces/api-requests";
import { keys, storageManager } from "./appStorage";
import { AppConfig } from "./environment";



enum method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

class AppService {
  cancelToken: any;
  formData: any;
  public domain: string = "";

  constructor() {

    this.domain = storageManager.get(keys.DOMAIN);

   

    axios.interceptors.response.use(
      (res: any) => {
        return Promise.resolve(res);
      },
      async (error: any) => {
        const originalConfig = error.config;
        if (error.response?.status === 401 || error.response?.status === 502) {
          try {
          } catch (_error) {
            return Promise.reject(_error);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  private static requestConfig(): AxiosRequestConfig {
    return {
      method: "get",
      baseURL: AppConfig.BACKEND_END_POINT,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  }

  private async execute<T>(
    method: method,
    url: string,
    body: any = undefined,
    _default: T,
    headers: any = {}
  ): Promise<APIResponse<T>> {
    // if (typeof this.cancelToken != typeof undefined) {
    //     this.cancelToken.cancel("Operation canceled due to new request.")
    // }
    // this.cancelToken = axios.CancelToken.source()

    let request = AppService.requestConfig();
    request.method = method;
    request.baseURL += url;
    request.headers = { ...request.headers, ...headers };

    // request.cancelToken = this.cancelToken.token
    if (body) request.data = JSON.stringify(body);

    return await axios(request)
      .then((res: any) => new APIResponse<T>(res?.status, res?.data as T))
      .catch((err: any) => {
        return new APIResponse<T>(err?.response?.status, _default, {
          message: err?.response?.data.message,
        });
      });
  }
  private async mediaExecute(
    method: method,
    url: string,
    body: any = undefined
  ) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (method === "POST") {
      return axios
        .post(`${AppConfig.BACKEND_END_POINT}${url}`, body, config)
        .then((res: any) => res?.data)
        .catch((err: any) => err);
    }
  }
  //Upload cover image
  async uploadMedia(file: any, body: any) {
    this.formData = new FormData();
    this.formData.append("asset", file);
    this.formData.append("publication", JSON.stringify(body));

    return await this.mediaExecute(
      method.POST,
      "v1/api/assets/single-file",

      this.formData
    );
  }
  async uploadMediaForEditor(file: any) {
    return await this.mediaExecute(
      method.POST,
      "v1/api/assets/single-file",
      file
    );
  }
  async login(data: any) {
    const response = await this.execute(
      method.POST,
      "v1/api/identity/admin-signin",
      data,
      {} as any
    );
    return response;
  }
  
   async refresh(data: any) {
    const response = await this.execute(
      method.POST,
      "v1/api/identity/refresh",
      data,
      {} as any
    );
    return response;
   }

  async signUp(data: any) {
    return await this.execute(
      method.POST,

      "v1/api/identity/signup",

      data,

      {} as any
    );
  }
  // get user details
  getUserById = async (id: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/user/${id}`,
      null,
      {} as any
    );
    return response;
  };
  // get roles by publication id and user id
  getRolesByPublicationId = async (publicationId: number, userId: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/role/userPermission/${publicationId}/user/${userId}`,
      null,
      {} as any
    );
    return response;
  };
  // getPublicationMembersById
  getPublicationMembersById = async (publicationId: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/publication/members/${publicationId}`,
      null,
      {} as any
    );
    return response;
  };
  //all blog data
  getAllStories = async (
    size: number,
    page: number,
    search: string,
    status: string
  ) => {
    const response = await this.execute(
      method.GET,
      `v1/api/story?pageSize=${size}&pages=${page}&searchTerm=${search}&status=${status}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };


  getStoriesByName = async (search: string) => {
    const response = await this.execute(
      method.GET,
      `v1/api/story?searchTerm=${search}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //get public users
  getUserByName = async (search: string) => {
    let test = "";
    if (typeof search !== "undefined" && search?.length > 0) {
      test += `&search=${search}`;
    }
    const response = await this.execute(
      method.GET,
      `v1/api/public/users?${test}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  getBlogData = async (size: number, page: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/story?pageSize=${size}&pages=${page}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  getCategories = async (searchTerm: string) => {
    let test = "";

    if (typeof searchTerm !== "undefined" && searchTerm.length > 0) {
      test += `?searchTerm=${searchTerm}`;
    }
    const response = await this.execute(
      method.GET,
      `/v1/api/category${test}`,
      null,

      {} as any
    );

    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //topic
  getTopics = async () => {
    const response = await this.execute(
      method.GET,
      `/v1/api/topic`,
      null,
      {} as any
    );

    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };

  //story by slug
  getStoriesBySlug = async (id: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/story/${id}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //category
  getCategory = async () => {
    const response = await this.execute(
      method.GET,
      `v1/api/category`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //category private
  getCategoryPrivate = async () => {
    const response = await this.execute(
      method.GET,
      `v1/api/category`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  getstoryPrivate = async () => {
    const response = await this.execute(
      method.GET,
      `v1/api/category`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //patch story
  updateStory = async (id: number, data: any) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/story/${id}`,
      data,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //create story
  createStory = async (data: any) => {
    const response = await this.execute(
      method.POST,
      `v1/api/story`,
      data,
      {} as any
    );
    return response;
  };
  //delete story
  deleteStory = async (id: number) => {
    const response = await this.execute(
      method.DELETE,
      `v1/api/story/${id}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //delete category
  deleteCategoryById = async (id: number) => {
    const response = await this.execute(
      method.DELETE,
      `v1/api/category/${id}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //category stories
  getCategorybyId = async (id: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/category/${id}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //category by domain
  getCategorybyDomain = async (domain: any, size: number, page: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/category?pageSize=${size}&pages=${page}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  getCategorybyStatus = async (
    domain: any,
    size: number,
    page: number,
    status: string
  ) => {
    const response = await this.execute(
      method.GET,
      `v1/api/category?pageSize=${size}&pages=${page}&status=${status}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  getCategorybyOnlyDomain = async (domain: any) => {
    const response = await this.execute(
      method.GET,
      `v1/api/category`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //category Edit
  patchCategorybyId = async (id: number, data: any) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/category/${id}`,
      data,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //check slug
  checkSlug = async (slug: string) => {
    const response = await this.execute(
      method.POST,
      `v1/api/story/checkSlug/${slug}`,
      null,
      {} as any
    );

    return response.response;
  };
  //all publication
  getAllPublication = async (size: number, page: number, search: string) => {
    let test = "";
    if (typeof search !== "undefined" && search.length > 0) {
      test += `&searchTerm=${search}`;
    }
    const response = await this.execute(
      method.GET,
      `v1/api/publication?pageSize=${size}&pages=${page}${test}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //publication by id
  getPublicationById = async (id: any) => {
    const response = await this.execute(
      method.GET,
      `v1/api/publication/${id}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  //seo update
  updateSeo = async (id: number) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/seo/${id}`,
      null,
      {} as any
    );
    return response;
  };
  //patch publication
  updatePublication = async (id: number, data: any) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/publication/${id}`,
      data,
      {} as any
    );
    return response;
  };
  //post category
  postCategory = async (data: any) => {
    const response = await this.execute(
      method.POST,
      `v1/api/category`,
      data,

      {} as any
    );

    return response;
  };
  //post publication
  postPublication = async (data: any) => {
    const response = await this.execute(
      method.POST,
      `v1/api/publication`,
      data,

      {} as any
    );

    return response;
  };
  //delete publication
  deletePublication = async (id: number) => {
    const response = await this.execute(
      method.DELETE,
      `v1/api/publication/${id}`,
      null,
      {} as any
    );
    return response;
  };

  //all users
  UserByEmail = async (email: string) => {
    const response = await this.execute(
      method.GET,
      `v1/api/user/${email}`,
      null,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };
  // update user details
  updateUser = async (data: any, id: number) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/user/${id}`,
      data,
      {} as any
    );
    return response;
  };
  // all user details
  allUser = async (size: number, page: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/users?pageSize=${size}&pages=${page}`,
      null,
      {} as any
    );
    return response;
  };
  //search all user details
  searchAllUser = async () => {
    const response = await this.execute(
      method.GET,
      `v1/api/users`,
      null,
      {} as any
    );
    return response;
  };
  //add member
  addMember = async (id: number, data: any) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/publication/members/add/${id}`,
      data,
      {} as any
    );
    return response;
  };
  //add member
  deleteMember = async (id: number, data: any) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/publication/members/remove/${id}`,
      data,
      {} as any
    );
    if (response.success()) return response.response;
    if (response.failed()) return response.errMessage;
  };

  //change status of story
  changeStatus = async (id: number, data: any) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/story/status/${id}`,
      data,
      {} as any
    );
    return response;
  };
  //change status of pubication
  changePublication = async (id: number, data: any) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/publication/status/${id}`,
      data,
      {} as any
    );
    return response;
  };
  //alt text
  altText = async (id: number, data: any) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/assets/${id}`,
      data,
      {} as any
    );
    return response;
  };
  //add comment
  addComment = async (data: any) => {
    const response = await this.execute(
      method.POST,
      `v1/api/comment`,
      data,
      {} as any
    );
    return response;
  };
  // delete comment
  deleteComment = async (id: number) => {
    const response = await this.execute(
      method.DELETE,
      `v1/api/comment/${id}`,
      null,
      {} as any
    );
    return response;
  };
  //post home page data
  postHomePageData = async (data: any) => {
    const response = await this.execute(
      method.POST,
      `v1/api/home`,
      data,
      {} as any
    );
    return response;
  };

  //get home data
  getHomeData = async () => {
    const response = await this.execute(
      method.GET,
      `v1/api/home`,
      null,
      {} as any
    );
    return response;
  };
  //get home data by id
  getHomeDataById = async (id: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/home/${id}`,
      null,
      {} as any
    );
    return response;
  };
  //delete home data by id
  deleteHomeDataById = async (id: number) => {
    const response = await this.execute(
      method.DELETE,
      `v1/api/home/${id}`,
      null,
      {} as any
    );
    return response;
  };
  //update home page data
  updateHomePageData = async (id: string, data: any) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/home/add/${id}`,
      data,
      {} as any
    );
    return response;
  };
  //remove story from home
  removeStoryFromHome = async (id: string, data: any) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/home/remove/${id}`,
      data,
      {} as any
    );
    return response;
  };
  //get assests data
  getAssestsData = async (size: number, page: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/assets?pageSize=${size}&pages=${page}`,
      null,
      {} as any
    );
    return response;
  };
  //get assests data by id
  getAssestsDataById = async (id: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/assets/${id}`,
      null,
      {} as any
    );
    return response;
  };
  //delete assests data by id
  deleteAssestsDataById = async (id: number) => {
    const response = await this.execute(
      method.DELETE,
      `v1/api/assets/${id}`,
      null,
      {} as any
    );
    return response;
  };
  //post role
  postRole = async (data: any) => {
    const response = await this.execute(
      method.POST,
      `v1/api/role`,
      data,
      {} as any
    );
    return response;
  };
  //get assests data by id
  getRoleByPublicationId = async (id: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/role/publication/${id}`,
      null,
      {} as any
    );
    return response;
  };
  //get modules
  getModules = async () => {
    const response = await this.execute(
      method.GET,
      `v1/api/role/getmodules`,
      null,
      {} as any
    );
    return response;
  };
  //get assests data by id
  getRoleById = async (id: number) => {
    const response = await this.execute(
      method.GET,
      `v1/api/role/${id}`,
      null,
      {} as any
    );
    return response;
  };
  //Patch role
  patchRoleById = async (id: string, data: any) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/role/${id}`,
      data,
      {} as any
    );
    return response;
  };

  //delete role
  deleteRole = async (id: number) => {
    const response = await this.execute(
      method.DELETE,
      `v1/api/role/${id}`,
      null,
      {} as any
    );
    return response;
  };
  //user role by id
  patchUserRoleById = async (id: number, data: any) => {
    const response = await this.execute(
      method.PATCH,
      `v1/api/publication/${id}/role`,
      data,
      {} as any
    );
    return response;
  };
  //redirectional login
  redirectToAuth = (
    ref: string,
    domain: string,
    protocol: string,
    path: string
  ) => {
    window.location.replace(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGIN_URL}?ref=${ref}&path=${path}&domain=${domain}&protocol=${protocol}`
    );
  };
  //assign role
  assignRole = async (data: any) => {
    const response = await this.execute(
      method.POST,
      `v1/api/secretPath/master/assignRole`,
      data,
      {} as any
    );
    return response;
  };

  getPublicationByDomain = async (domain: string) => {
    const response = await this.execute(
      method.GET,
      `v1/api/publication/domain/${domain}`,
      null,
      {} as any
    );
    return response;
  };
}

export const appService: AppService = new AppService();

