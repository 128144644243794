
import styles from "../styles/components/Header.module.scss";

import React, { useState } from "react";
import { MdCancel } from "react-icons/md";
import { BsBell, BsBellFill } from "react-icons/bs";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { readCapabilities } from "../components/index";
import { StroiesTypes } from "../services/cacheKeys";
import { AppConfig } from "../services/environment";
import { resetPublication } from "../store/publication/publicationSlice";
import { reset } from "../store/user/userSlice";
import Image from "./Image";

export interface IHeader {
  label?: string;
  isAuthor?: boolean;
  handleClick?: any;
  showMenu?: boolean;
  setShowMenu?: any;
  showNotification?: boolean;
  setShowNotification?: any;
}
const customStyles = {
  content: {
    top: "26%",
    left: "75.2%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Header: React.FC<IHeader> = ({
  handleClick,
  showMenu,
  setShowMenu,
  showNotification,
  setShowNotification,
}) => {


  const dispatch = useDispatch<any>();

  const { permissions, domain, userId, token }: any = useSelector<any>(
    (state) => state.auth
  );


  const { user }: any = useSelector<any>((state: any) => state.auth);

  const logOut = () => {
    dispatch(reset());
    dispatch(resetPublication());
    localStorage.clear();
    if (userId) {
      redirectToFrontEnd();
    }
  };

  //for switching domains
  const redirectToAdmin = (domain: any) => {
    const protocol = window.location.protocol.replace(/:$/, "");  
    if (domain) {
      window.open(
        `${
          AppConfig.BACKEND_END_POINT
        }${"v1/api/identity/redirect-admin"}?accesstoken=${token}&domain=${domain}&protocol=${protocol}&referrer=${
          AppConfig.ADMIN_URL
        }`,
        "_self"
      );
    }
  };

  const redirectToFrontEnd = () => {
    window.location.replace(`https://www.thevoiceofwoman.com/`);
  };


  //modal

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <header className={` h-20 `}>
        <div className={`    relative flex justify-between items-center py-3`}>
          <div className="w-full">
            <nav className="bg-[#fff7f7] fixed  top-0 z-[100]  flex justify-end items-center w-full md:px-[7.2%] ">
              <div className="w-full">
                <div className="flex items-center h-20 w-full">
                  <div className="px-2 pr-7 flex items-baseline md:items-center justify-between     w-full">
                    <div className="md:hidden flex-[0.1]"></div>

                    <div className="">
                      <Link to={"/"}>
                        <Image
                          media={{ url: "/images/vow_logo.png" }}
                          alt="Vow"
                          width={250}
                          height={40}
                          className=" mt-0.5 cursor-pointer  "
                        />
                        <p className="text-sm">(Admin)</p>
                      </Link>
                    </div>

                    <div className="">
                      <div className=" flex  md:space-x-2   justify-between items-center  xl:gap-4  mt-2">
                        <p
                          onClick={redirectToFrontEnd}
                          className={`${styles.write_header} fs-16 font_medium font_color_common cursor-pointer hidden md:block`}
                        >
                          {"Go to website"}
                        </p>
                        {permissions.length > 0 &&
                          (readCapabilities(
                            "story",
                            permissions
                          )?.allow?.includes("manage") ||
                            readCapabilities(
                              "story",
                              permissions
                            )?.allow?.includes("read", permissions)) &&
                          user?.id && (
                            <Link
                              to={`/story?status=${StroiesTypes.PUBLISHED}&page=1&pageSize=25`}
                            >
                              <p
                                onClick={handleClick}
                                className={`${styles.write_header} fs-16 font_medium font_color_common cursor-pointer hidden md:block`}
                              >
                                {"Stories"}
                              </p>
                            </Link>
                          )}
                        {permissions.length > 0 &&
                          (readCapabilities(
                            "category",
                            permissions
                          )?.allow?.includes("manage") ||
                            readCapabilities(
                              "category",
                              permissions
                            )?.allow?.includes("read")) &&
                          user?.id && (
                            <Link
                              to={`/categories?status=${StroiesTypes.PUBLISHED}&page=1&pageSize=12`}
                            >
                              <p
                                className={`${styles.write_header} fs-16 font_medium font_color_common cursor-pointer hidden md:block`}
                              >
                                {"Categories"}
                              </p>
                            </Link>
                          )}

                        {user?.id ? (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowMenu(!showMenu);
                              setShowNotification(false);
                            }}
                          >
    
                            <p
                              className={`hidden md:block fs-16 font_medium font_color_common cursor-pointer ${styles.write_header}`}
                            >
                              {user?.username}
                            </p>
                          </span>
                        ) : (
                          <p
                            className={`hidden md:block fs-16 ml-3 font_medium font_color_common cursor-pointer ${styles.write_header}`}
                            onClick={redirectToFrontEnd}
                          >
                            Sign In
                          </p>
                        )}
                        {showMenu ? (
                          <div
                            className={`${styles.user_information} right-36 absolute  top-16 mt-1 p-5`}
                          >
                           
                            <p
                              onClick={(e: any) => {
                                openModal();
                                e.stopPropagation();
                              }}
                              className="fs-14 font_color_tertiary font_normal pt-2 cursor-pointer"
                            >
                              Switch Publication
                            </p>

                            <Link to="/settings/account">
                              <p className="fs-14 font_color_tertiary font_normal pt-2 cursor-pointer">
                                Settings
                              </p>
                            </Link>
                            
                            <Modal
                              isOpen={modalIsOpen}
                              onRequestClose={closeModal}
                              style={customStyles}
                            >
                              <div className="flex justify-between items-center gap-x-4">
                                <p className="text-lg font-semibold">
                                  Select Publication
                                </p>
                                <MdCancel onClick={closeModal} />
                              </div>
                              {user?.publicationOwner && (
                                <>
                                  <p className="font-semibold text-gray-400">
                                    Owner
                                  </p>
                                  <p
                                    className="cursor-pointer"
                                    onClick={() =>
                                      redirectToAdmin(
                                        user?.publicationOwner?.domain
                                      )
                                    }
                                  >
                                    {user?.publicationOwner?.name}
                                  </p>
                                </>
                              )}
                              <hr />
                              <p className="font-semibold text-gray-400">
                                Member
                              </p>
                              {user?.publicationMember?.map((item: any) => {
                                return (
                                  <p
                                    className="cursor-pointer"
                                    key={item?.id}
                                    onClick={() =>
                                      redirectToAdmin(item?.domain)
                                    }
                                  >
                                    {item?.name}
                                  </p>
                                );
                              })}
                            </Modal>
                            <div className="flex justify-start items-center mt-4">
                              {user?.profile?.logo?.path ? (
                                <img
                                  src={user?.profile?.logo?.path}
                                  className="h-[29px] w-[29px] rounded-full"
                                  alt=""
                                />
                              ) : (
                                <div
                                  className={`flex justify-center items-center bg-pink-400 text-[13px] rounded-full w-[21px] h-[21px] text-white font-semibold`}
                                >
                                  {user?.username
                                    .split(" ")
                                    .map((item: string, index: number) => {
                                      return (
                                        <div key={index}>
                                          <span className="">
                                            {item.charAt(0).toUpperCase()}
                                          </span>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                              <p className="fs-14 ml-3 font-semibold font_color_common cursor-pointer">
                                {user?.username}
                              </p>
                            </div>
                          </div>
                        ) : null}
                        {user?.id ? (
                          <p
                            className="cursor-pointer select-none hidden md:block"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowNotification(!showNotification);
                              setShowMenu(false);
                            }}
                          >
                            {!showNotification ? (
                              <BsBell
                                size={18}
                                className={``}
                                color="#757575"
                              />
                            ) : (
                              <BsBellFill
                                size={20}
                                className={``}
                                style={{ fill: "#FF005B" }}
                              />
                            )}
                          </p>
                        ) : null}
                        {showNotification ? (
                          <div
                            className={`${styles.notification} right-36 absolute  top-16 mt-1 px-[10px] pb-5 divide-y-[1px]  space-y-5  `}
                          >
                            <div className="flex items-center gap-x-2 pt-5">
                              <img
                                className="rounded-full w-[32px] h-[32px]"
                                src="/images/Ellipse30@2x.png"
                                alt=""
                              />
                              <p className="text-xs text-[#251D19]">
                                Lorem Ipsum is simply dummy text of the printing
                                typesetting industry. text of
                              </p>
                            </div>
                            <div className="flex items-center gap-x-2 pt-5">
                              <img
                                className="rounded-full w-[32px] h-[32px]"
                                src="/images/Ellipse30@2x.png"
                                alt=""
                              />
                              <p className="text-xs text-[#251D19]">
                                Lorem Ipsum is simply dummy text of the printing
                                typesetting industry. text of
                              </p>
                            </div>
                            <div className="flex items-center gap-x-2 pt-5">
                              <img
                                className="rounded-full w-[32px] h-[32px]"
                                src="/images/Ellipse30@2x.png"
                                alt=""
                              />
                              <p className="text-xs text-[#251D19]">
                                Lorem Ipsum is simply dummy text of the printing
                                typesetting industry. text of
                              </p>
                            </div>
                            <div className="flex items-center gap-x-2 pt-5">
                              <img
                                className="rounded-full w-[32px] h-[32px]"
                                src="/images/Ellipse30@2x.png"
                                alt=""
                              />
                              <p className="text-xs text-[#251D19]">
                                Lorem Ipsum is simply dummy text of the printing
                                typesetting industry. text of
                              </p>
                            </div>
                            <div className="flex items-center gap-x-2 pt-5">
                              <img
                                className="rounded-full w-[32px] h-[32px]"
                                src="/images/Ellipse30@2x.png"
                                alt=""
                              />
                              <p className="text-xs text-[#251D19]">
                                Lorem Ipsum is simply dummy text of the printing
                                typesetting industry. text of
                              </p>
                            </div>
                            <div>
                              <Link to="/notifications">
                                <button className="text-xs cursor-pointer text-[#251D19] font-medium pt-5">
                                  See All Notifications
                                </button>
                              </Link>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
