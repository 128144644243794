import { useState } from "react";
import { appService } from "../services/appServices";
import styles from "../styles/components/DescriptionAbout.module.scss";
import { Button, PrimaryLayout } from "./index";
import isFieldEmpty from "./validate";


export interface Idescription {
  Name?: string;
  Domain?: string;
  TagLine?: string;
}
const Description = ({ Name, Domain, TagLine }: Idescription) => {
  const [showMenu, setShowMenu]: any = useState(false);
   const [error, setError]: any = useState({});
  const [publicationData, setPublicationData] = useState({
    name: Name,
    slug: "",
    tagLine: TagLine,
    aboutUs: "",
    domain: Domain,
  });
  const checkValidations = (value?: any) => {
    let flag = true;

    let data = { ...error };

    if (isFieldEmpty(publicationData.name, "Slug", setError)) flag = false;

    if (isFieldEmpty(publicationData.domain, "AboutUs", setError)) flag = false;
  

    return flag;
  };
  const postPublication = async () => {
    if (checkValidations()) {
    await appService.postPublication(publicationData);}
  };

  return (
    <div onClick={() => setShowMenu(false)}>
      <PrimaryLayout
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        isAuthor={true}
      />
      <div className="pt-28">
        <aside className="basis-2/5 justify-center flex">
          <div>
            <div className={`${styles.description_public} px-10 mb-10 mt-10`}>
              <div className="flex flex-col">
                <div className="mt-5">
                  <p className="fs-14 font-medium font_color_common">
                    Description
                  </p>
                  <textarea
                    className={`w-full ${styles.description_name} placeholder-[#757575] fs-14 font_normal justify-center font_color_tertiary p-2 mt-2`}
                    placeholder="60 words"
                    value={publicationData.slug}
                    onChange={(e) =>
                      setPublicationData({
                        ...publicationData,
                        slug: e.target.value,
                      })
                    }
                  ></textarea>
                {error && <p className="text-danger">{error["Slug"]}</p>}
                </div>
                <div className="mt-5">
                  <p className="fs-14 font-medium font_color_common">
                    About Us
                  </p>
                  <textarea
                    className={`w-full ${styles.about_name} placeholder-[#757575] fs-14 font_normal justify-center font_color_tertiary p-2 mt-2`}
                    placeholder="300 words"
                    value={publicationData.aboutUs}
                    onChange={(e) =>
                      setPublicationData({
                        ...publicationData,
                        aboutUs: e.target.value,
                      })
                    }
                  ></textarea>
                {error && <p className="text-danger">{error["AboutUs"]}</p>}
                </div>
                <div className="flex justify-center">
                  <Button
                    onClick={postPublication}
                    label="Done"
                    classProperty={`btn_tertiary_1 font_color_tertiary items-center font-medium fs-14 my-3 ${styles.next_btn}`}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Description;
