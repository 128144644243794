class APIResponse<T> {
    public respCode: number;
    public response: T;
    public errMessage: { message: string };

    constructor(code: number, response: T, err?: { message: string }) {
        this.respCode = code;
        this.response = response;
        this.errMessage = err ?? {message: ''};
    }
    success = (): boolean => this.respCode >= 200 && this.respCode <= 204;
    failed = (): boolean => !this.success();
}

export default APIResponse;
