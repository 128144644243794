import Button from "./Button";
import PrimaryLayout from "./PrimaryLayout";
import Image from "./Image";
import Loader from "./Loader";
import Description from "./Description";

export { Button, Description, PrimaryLayout, Image, Loader };
export const readCapabilities: any = (subject: string, permissions: any) => {
  return permissions.find((item: any) => {
    return item.subject == subject;
  });
};
