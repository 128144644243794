import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { appService } from "../../services/appServices";
import { storageManager } from "../../services/appStorage";
import { CacheKeys } from "../../services/cacheKeys";

export interface IUserslice {
  token: string;
  refreshToken: string;
  userIdNumber: string;
  domain: string;
  user: any;
  permissions: any;
  permissionsName: string;
  members: any[];
  stautusCode: null;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}

const initialState:IUserslice = {
  token: "",
  refreshToken: "",
  userIdNumber: "",
  domain:"",
  user: {},
  permissions: {},
  permissionsName:"",
  members:[],
  stautusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};
export const login = createAsyncThunk("auth/login", async (data: any) => {
  const response = await appService.login(data);

  return response;
});
export const signUp = createAsyncThunk("auth/signUp", async (data: any) => {
  const response = await appService.signUp(data);

  return response;
});
export const getUserId = createAsyncThunk("auth/getuser", async (id: any) => {
  
  const response = await appService.getUserById(id);

  return response;
});
export const getRoleId = createAsyncThunk("auth/getrole", async (data:any) => {
  const { publicationId, userId } = data;
  
  const response = await appService.getRolesByPublicationId(publicationId, userId);

  return response;
});

export const fetchPublicationMembersById = createAsyncThunk(
  "auth/getmembers",
  async (id: any) => {
    const response = await appService.getPublicationMembersById(id);

    return response;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset() {
      return {
        ...initialState,
      };
    },
    removePublication: (state: any) => ({
      ...state,
      user: {
        ...state.user,
        publicationOwner: null,
      },
    }),

    setRealTimePermissions(state: any, action: any) {
      state.permissions = action.payload.permissions;
    },
    addMemberById(state: any, action: any) {
      state.members.members = action.payload.response.members;
    },
    deleteMemberById(state: any, action: any) {
      state.members.members = action.payload.members;
    },
    updateUserInfoSlice(state: any, action: any) {
      state.user = action.payload;
    },

    updateUserData: (state: any, action: any): any => {
      state.token = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.userIdNumber = action.payload.userId;
      state.domain = action.payload.domain;

    
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state: any, action: any) => {
        state.isLoading = false;

        if (
          action.payload.respCode === 403 ||
          action.payload.respCode === 400 ||
          action.payload.respCode === 500 ||
          action.payload.respCode === 404 ||
          action.payload.respCode === undefined
        ) {
          state.isError = true;
          state.message = action.payload.errMessage.message;
        } else if (action.payload.response.statusCode === 401) {
          state.isError = true;
          state.message = action.payload.response.message;
        } else {
          state.isSuccess = true;
          state.isError = false;
          state.token = action.payload.response.accessToken;
          state.refreshToken = action.payload.response.refreshToken;
          state.user = action.payload.response.user;
          storageManager.save(
            CacheKeys.TOKEN,
            action.payload.response.accessToken
          );
          storageManager.save("refresh", action.payload.response.refreshToken);
        }
      })
      .addCase(login.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isError = action.payload;

        state.user = {};
      })
      //signUp
      .addCase(signUp.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(signUp.fulfilled, (state: any, action: any) => {
        state.isLoading = false;

        if (
          action.payload.respCode === 403 ||
          action.payload.respCode === 400 ||
          action.payload.respCode === 409 ||
          action.payload.respCode === 500 ||
          action.payload.respCode === undefined
        ) {
          state.isError = true;
        } else {
          state.isSuccess = true;

          state.user = action.payload.response;
        }
      })
      //getUserById
      .addCase(getUserId.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getUserId.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.user = action.payload.response;
      })
      //getMembersByPublicationId
      .addCase(fetchPublicationMembersById.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(
        fetchPublicationMembersById.fulfilled,
        (state: any, action: any) => {
          state.isLoading = false;
          state.members = action.payload.response;
        }
      )
      //getRoleById
      .addCase(getRoleId.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getRoleId.fulfilled, (state: any, action: any) => {
        state.isLoading = false;

        state.permissionsName = action.payload.response.name;
        action.payload.response.permissions = JSON.parse(
          action.payload.response.permissions
        );
        state.permissions = action.payload.response.permissions;
      });
  },
});

export const {
  reset,
  updateUserInfoSlice,
  setRealTimePermissions,
  removePublication,
  updateUserData,
  deleteMemberById,
  addMemberById,
} = authSlice.actions;
export default authSlice.reducer;
