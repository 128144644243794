import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { appService } from "../../services/appServices";

export const fetchPublication = createAsyncThunk(
  "publication/all",
  async (data: any) => {
    const { pageSize, page, search } = data;
    const response = await appService.getAllPublication(pageSize, page, search);
    return response;
  }
);

export const fetchPublicationById = createAsyncThunk(
  "publication/id",
  async (id: any) => {
    const response = await appService.getPublicationById(id);
    return response;
  }
);
export const fetchPublicationByDomain = createAsyncThunk(
  "publication/domain",
  async (domain: string) => {
    const response = await appService.getPublicationByDomain(domain);
    return response;
  }
);
export const deletePublicationById = createAsyncThunk(
  "publication/delete",
  async (id: number) => {
    const response = await appService.deletePublication(id);
    return response;
  }
);

export const createPublication = createAsyncThunk(
  "publication/create",
  async (data: any) => {
    const response = await appService.createStory(data);
    return response;
  }
);

export const deleteMemberById = createAsyncThunk(
  "delete/member",
  async (data: any) => {
    const { id, user } = data;
    const response = await appService.deleteMember(id, user);

    return response;
  }
);
export const fetchHome = createAsyncThunk(
  "publication/create",
  async (id: any) => {
    const response = await appService.getHomeDataById(id);
    return response;
  }
);

interface IInitialState {
  // allPublication: string[];
  publication: {
    Home: {
      createdAt: string;
      id: number;
      link: string;
      profileId: number;
      number: number;
      updatedAt: string;
      userId: number;
    };
    aboutUs: string;
    domain: string;
    id: number;
    members:any,
    name: string;
    owner:any;
    ownerId:number;
    profile:any;
    profileId:number;
    seo:any;
    slug:string;
    suscriber:number;
    tagLine:string;
    tags:any[];
  }
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  home: object;
  message: string;
}
const initialState: IInitialState = {
  // allPublication: [],
  publication: {
     Home: {
      createdAt: "",
      id: 0,
      link: "",
      profileId: 0,
      number: 0,
      updatedAt: "",
      userId: 0,
    },
    aboutUs: "",
    domain: "",
    id: 0,
    members:{},
    name: "",
    owner:{},
    ownerId:0,
    profile:{},
    profileId:0,
    seo:{},
    slug:"",
    suscriber:0,
    tagLine:"",
    tags:[],
  },
  isError: false,
  isSuccess: false,
  isLoading: false,
  home: {},
  message: "",
};

const publicationSlice = createSlice({
  name: "publication",
  initialState,
  reducers: {
    resetPublication() {
      return {
        ...initialState,
      };
    },
    updatePublicationData(state: any, action: any) {
      state.publication = action.payload;
    },
    updateHomeTrending(state: any, action: any) {
      state.home.trending.push(action.payload.trending[0]);
    },
    updateHomeInspiration(state: any, action: any) {
      state.home.inspiration.push(action.payload.inspiration[0]);
    },
    updateHomeTopFeatures(state: any, action: any) {
      state.home.topFeatures.push(action.payload.topFeatures[0]);
    },
    updateHome(state: any, action: any) {
      state.home = action.payload.response;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPublication.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.isSuccess = true;

        state.allPublication?.push(action.payload);
      })
      .addCase(fetchPublicationById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPublicationById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.publication = action.payload;
      })
      .addCase(fetchPublicationById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })

      .addCase(fetchPublicationByDomain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPublicationByDomain.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.publication = action.payload.response;
      })
      .addCase(fetchPublicationByDomain.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })

      .addCase(deletePublicationById.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.isSuccess = true;
      })

      .addCase(deleteMemberById.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.publication = action.payload;
      })
      .addCase(fetchHome.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHome.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.home = action.payload.response;
      })
      .addCase(fetchHome.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const {
  resetPublication,
  updatePublicationData,
  updateHomeTrending,
  updateHomeInspiration,
  updateHomeTopFeatures,
  updateHome,
} = publicationSlice.actions;
export const selectPublication = (state: any) => state.publication.publication;
export const allSelectPublication = (state: any) =>
  state.publication.allPublication;
export default publicationSlice.reducer;
