import styles from '../styles/components/Button.module.scss';

export interface IButton {
  label: string;
  classProperty?: string;
  onClick?: () => void;
  disabled?:any;
}

const Button: React.FC<IButton> = ({ label, classProperty, onClick,disabled }) => {
  return (
    <button
      className={`${classProperty} ${styles.default_btn}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
