const isFieldEmpty = (
 
  field: any,
  name: string,
  object: any,
  required: boolean = true
) => {
  if (
    typeof field == undefined ||
    !field ||
    field?.length == 0 ||
    field == null ||
    (typeof field == "string" && field.trim() == "")
  ) {
    object((prev: any) => {
      return {
        ...prev,
        [name]: ` ${
          required ? `${name} is required` : `Please fill ${name} field`
        }`,
      };
    });

    return true;
  } else {
    object((prev: any) => {
      return { ...prev, [name]: "" };
    });

    return false;
  }
};

export default isFieldEmpty;
