
export enum keys {
    TOKEN="_uToKeNjhj",
    REFRESH_TOKEN="regrjllkre",
    USER_ID="3324",
    DOMAIN="_doMaIn"
}


export default class LocalStorageManager{

    private storage: Storage;
    constructor(useSession: boolean = false) {
        this.storage = useSession ? sessionStorage : localStorage;
    }

    private encode = (msg: any) : string => {
        let _msg = JSON.stringify(msg);
        return _msg;
    }

    private decode = (msg: any): any => {
        
            return JSON.parse(msg);
    
    }

    save = (key: string, value: any): boolean => {
        this.storage.setItem(key, this.encode(value));
        return true;
    }

    get = <T>(key: string) : T => {
        return (this.decode(this.storage.getItem(key)  )) ;
    }

    exists = (key: string) => {
        return !!this.storage.getItem(key);
    }

    clear = (): boolean => {
        this.storage.clear();
        return true;
    }
    keys = (): string[] => {
        return Object.keys(this.storage)
    }
}

export const storageManager = new LocalStorageManager();

